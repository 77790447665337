<ng-template #dealsModal let-modal>
  <div class="modal-content scroll">
      <div class="modal-header">
          <div>
              <h5 class="modal-title w-100" id="deal_today"> Deal Today </h5>
              <p class="text-content"> Recommended deals for you</p>
          </div>
          <app-button [type]="'button'" [class]="'btn btn-close'" [id]="'close_deal_modal_btn'" (click)="modal.dismiss('Cross click')">
              <i class="ri-close-line"></i>
          </app-button>
      </div>
      <div class="modal-body">
        <div class="deal-offer-box">
          <ul class="deal-offer-list">
            <li class="list-1" *ngFor="let product of productdetails">
                <div class="deal-offer-contain">
                    <a [routerLink]="['/product/', product?.productname,product?.id,product?.skid]" class="deal-image" (click)="closeModal()">
                        <img [src]="product?.product_thumbnails?.original_url ?
                                 imageUrl+ product?.product_thumbnails?.original_url :
                                    'assets/images/product.png'" alt="product">
                    </a>
                    <a [routerLink]="['/product/', product?.slug]" class="deal-contain">
                        <h5 class="fw-semibold">{{product?.productname }}</h5>
                        <h6>
                            {{ product?.salesrate }}
                            <del *ngIf="productdetails.price">{{ product?.price }}</del>
                            <span *ngIf="productdetails.stock">{{ product?.stock }}</span>
                        </h6>
                    </a>
                </div>
            </li>
          </ul>
        </div>
        <!-- No Data -->
        <app-no-data
            [class]="'no-data-added bg-light'"
            [text]="'No Product Found'"
            *ngIf="!products?.length">
        </app-no-data>
      </div>
  </div>
</ng-template>
