<!-- Cart Style Basic -->
<app-button
  [type]="'button'"
  [class]="'btn p-0 position-relative header-wishlist'"
  [spinner]="false"
  [id]="'cart_sidebar_btn'"
      this.cartShow = true;
  (click)="cartStyle == 'cart_sidebar' && cartShow  ? cartToggle(false): cartToggle(true)" *ngIf="style == 'basic'">
    <i class="ri-shopping-cart-line"></i>
    <span class="position-absolute top-0 start-100 translate-middle badge" *ngIf="this.cartList?.length">
        {{ this.cartList?.length }} <span class="visually-hidden">Unread Messages</span>
    </span>
</app-button>

<!-- Cart On Hover -->
<div class="onhover-div"
    [ngClass]="{ 'fixed-cart': cartStyle == 'cart_sidebar' }"
    [class.show]="(sidebarCartOpen$ | async)">
    <div class="cart-title">
        <h4>Shopping Cart</h4>
        <a href="javascript:void(0)" (click)="cartToggle(false)">
            <i class="ri-close-line"></i>
        </a>
    </div>
    <ul class="cart-list" *ngIf="this.cartList?.length">
        <li class="product-box-contain" *ngFor="let item of this.cartList">
            <div class="drop-cart">
                <a href="javascript:void(0)" class="drop-image" [routerLink]="['/product/', item?.product_name,item?.product_id,item?.sku_id]">
                    <img [src]="item?.product_image ? imageUrl+item?.product_image 
                            : 'assets/images/product.png'" class="img-fluid" [alt]="item?.product_name">
                </a>
                <div class="drop-contain">
                        <h5>{{ item?.product_name ? item?.product_name : '' }}</h5>
                    <h6>{{ (item?.sales_rate ? item.sales_rate : '')  | currency:'INR':'symbol' }} * {{item?.product_quantity}}</h6>
                    <!-- <h5 class="gram" *ngIf="item?.variation">
                        {{ item?.variation?.selected_variation }}
                    </h5> -->
                    <!-- <div class="cart_qty qty-box product-qty">
                      <div class="input-group h-100">
                        <app-button
                            [class]="'btn qty-left-minus'"
                            [id]="'add-to-cart'+item.product.id"
                            [spinner]="false"
                            (click)="updateQuantity(item, -1)">
                            <i class="ri-subtract-line" *ngIf="item.quantity > 1"></i>
                            <i class="ri-delete-bin-line" *ngIf="item.quantity <= 1"></i>
                        </app-button>
                        <input class="form-control input-number qty-input" type="number" name="quantity" [value]="item?.quantity" readonly>
                        <app-button
                            [class]="'btn qty-left-minus'"
                            [id]="'qty-left-minus'+item.product.id"
                            [spinner]="false"
                            (click)="updateQuantity(item, 1)">
                            <i class="ri-add-line"></i>
                        </app-button>
                      </div>
                    </div> -->
                    <div>
                        <div class="header-button-group">
                            <app-button [class]="'delete-button close_button'" [id]="'cart_item_delete_btn'+item.id" [spinner]="false" (click)="delete(item?.id)">
                                <i class="ri-delete-bin-line"></i>
                            </app-button>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    </ul>
    <div class="empty-cart-box" *ngIf="noData">
        <i class="ri-shopping-cart-line"></i>
        <h5>{{ 'Your cart is currently empty'  }}</h5>
    </div>
    <div class="bottom-box">
        <p class="free">{{ 'Shipping and taxes are calculated at checkout' | translate }}</p>
        <div class="price-box">
            <h5>Total:</h5>
            <h4 class="fw-bold">{{ cartTotal  | currency:'INR':'symbol' }}</h4>
        </div>
        <div class="button-group">
            <a [routerLink]="['/cart']" class="btn btn-sm cart-button" (click)="cartToggle(false)">
                View cart
            </a>
            <a [routerLink]="['/checkout']" class="btn btn-sm cart-button theme-bg-color text-white" (click)="cartToggle(false)" *ngIf="this.cartList?.length">
                checkout
            </a>
        </div>
    </div>
</div>

