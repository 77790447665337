<div class="media recently-purchase" [class.show]="popup_enable ? show : popup_enable" *ngIf="popup">
    <img [src]="popup.produt_thumbnail ?
               image_url + popup.produt_thumbnail :
                'assets/images/product.png'" class="media-height" alt="product">
    <div class="media-body">
        <div>
            <div class="title d-block mb-0">
                {{ 'Someone Recently Purchased'}}
            </div>
            <!-- <a [routerLink]="['/product/', product.slug]"> -->
                <span class="product-name">
                    {{ popup.product_name }}
                </span>
            <!-- </a> -->
            <small class="timeAgo">{{formattedDate}}</small>
        </div>
    </div>
    <a href="javascript:void(0)" (click)="closePopup()" class="close-popup ri-close-fill"></a>
</div>
