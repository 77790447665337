import { Component, OnChanges, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CompareState } from '../../../state/compare.state';
import { GetCompare } from '../../../action/compare.action';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/shared/services/account.service';
import { error } from 'console';

@Component({
  selector: 'app-sticky-compare',
  templateUrl: './sticky-compare.component.html',
  styleUrls: ['./sticky-compare.component.scss']
})
export class StickyCompareComponent implements OnInit {

  @Select(CompareState.compareTotal) compareTotal$: Observable<number>;
  user_id: any;
  compares: any=[];
  totalcount:number=0;

  constructor(private store: Store, private service:ProductService,private AccountService: AccountService) {
    this.store.dispatch(new GetCompare());
  }
 

  
  ngOnInit(): void {
    this.service.getTotalCount().subscribe((res:any)=>{
        this.getTotalCounts();
    })
    

  }

  getTotalCounts(){
    const shid = environment?.sh_id;
    if(this.AccountService.getUserData()){
      this.user_id = this.AccountService.getUserData()?.id;
      this.service.getCompareProductList(shid,this.user_id).subscribe((result:any)=>{
        this.totalcount = result.length;
    },(error:any)=>{})
    }
    else{
      console.log('rrrrrr')
      
        
        var ans: any = localStorage.getItem('addtocompare')
        const parseData = JSON.parse(ans);
        this.totalcount=parseData.length;
        
    }
  }
}
