import { Component, Input, OnInit } from '@angular/core';
import { Option } from '../../../../../shared/interface/theme-option.interface';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-footer-copyright',
  templateUrl: './copyright.component.html',
  styleUrls: ['./copyright.component.scss']
})
export class CopyrightComponent implements OnInit {
  shopdetailes: any=[];
  shopName: any;

  constructor( private productservice:ProductService){


  }

ngOnInit(): void {
  this.productservice.getShoDetails(environment?.sh_id).subscribe((result:any)=>{
    console.log(result,'shopdetailessss')
    this.shopdetailes=result;
    this.shopName = this.shopdetailes?.shop_name;
    console.log(this.shopName,'nameee')
  })
}

}
