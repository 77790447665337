<ng-template #addressModal let-modal>
  <div class="modal-header">
    <h3 class="fw-semibold"> {{ (!address ? 'Add Address' : 'Edit Address')   }}</h3>
    <app-button [class]="'btn btn-close'" [spinner]="false" [id]="'address_modal_close_btn'"
      (click)="modal.dismiss('Cross click')">
      <i class="ri-close-line"></i>
    </app-button>
  </div>
  <form [formGroup]="form" class="">
    <div class="modal-body address-form">
      <div class="row g-4">
        <div class="col-xxl-12">
          <div class="">
            <label class="form-label" for="pname">Full Name</label>
            <input class="form-control" id="title" type="text" placeholder="Enter Full Name"
              formControlName="fullname" />
            <div class="invalid-feedback"
              *ngIf="submitted  && form.controls['fullname']?.errors?.['required']">
              Full Name is Required
            </div>
            <div *ngIf="form.controls['fullname'].touched && form.controls['fullname'].errors?.['pattern']" class="invalid-feedback">
              Invalid Format
               </div>
          </div>
        </div>
        <div class="col-12">
          <div class="">
            <label class="form-label" for="address1">Address</label>
            <input class="form-control" id="address" type="text" placeholder="Enter Address"
              formControlName="street" />
            <div class="invalid-feedback"
              *ngIf="submitted && form.controls['street']?.errors?.['required']">
              Address is Required
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="">
            <label class="form-label" for="email1">Email</label>
            <input class="form-control" id="email" type="text" placeholder="Enter Email"
              formControlName="email" />
            <div class="invalid-feedback"
              *ngIf="submitted && form.controls['email']?.errors?.['required']">
              Email is Required
            </div>
            <div class="invalid-feedback"
              *ngIf="form.controls['email'].touched && form.controls['email']?.errors?.['email']">
              Incorrect Format
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">Country</label>
            <!-- <select2 class="custom-select" [data]="countryArr ? countryArr : []"
              [placeholder]="'Select country' | translate" formControlName="country_id" (update)="countryChange($event)" >
            </select2> -->
            <select id="yourSelectElement" formControlName="country_id" class="form-control select2" (change)="countryChange($event,'')">
              <option *ngFor="let country of countryArr" [value]="country.label">{{ country.label }}</option>
            </select>
            <!-- <ng-select [items]="countryArr"
          
          bindLabel="name"
         (click)="$event.stopPropagation()"
          bindValue="id"
          placeholder="Select"  class="custom-select" (change)="countryChange($event.id)">
        </ng-select> -->
            <div class="invalid-feedback"
              *ngIf="submitted && form?.controls?.['country_id']?.errors?.['required']">
              Country is Required
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">State</label>
            <!-- <select2 class="custom-select" [data]="stateArr ? stateArr : []"
              [placeholder]="'Select state' | translate" formControlName="state_id">
            </select2> -->
            <select id="yourSelectElement" formControlName="state_id" class="form-control select2">
              <option *ngFor="let state of stateArr" [value]="state.label">{{ state.label }}</option>
            </select>
            <div class="invalid-feedback"
              *ngIf="submitted && form?.controls?.['state_id']?.errors?.['required']">
              State is Required
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">City</label>
            <input class="form-control" id="city" type="text" placeholder="Enter City" formControlName="city" />
            <div class="invalid-feedback"
              *ngIf="submitted && form.controls['city']?.errors?.['required']">
              City is Required
            </div>
            <div class="invalid-feedback"
            *ngIf="form.controls['city'].touched && form.controls['city']?.errors?.['city']">
            Incorrect Format
          </div>
          </div>
        </div>
        <div class="col-6">
          <div class="">
            <label class="form-label" for="address1">Pincode</label>
            <input class="form-control input-number " id="pincode" type="text" placeholder="Enter Pincode"
              formControlName="pincode" numbersOnly (input)="onInputChange()" />
              <p class="color " (click)="oncheck()"> {{ isChecked ? 'Changed' : 'Check' }} </p> 
              <p *ngIf="pincodemessage"[ngClass]="{'text-success': isPincodeValid, 'text-danger': !isPincodeValid}">
                {{ pincodemessage }}
              </p>
            <div class="invalid-feedback"
              *ngIf="submitted && form.controls['pincode']?.errors?.['required']">
              Pincode is Required
            </div>
            <div class="invalid-feedback" *ngIf="form.controls['pincode'].touched && form.controls['pincode']?.errors?.['pincodeInvalid']">
              Pincode is Invalid.
            </div>
          </div>
        </div>
        <div class="col-12 phone-field">
          <div class="">
            <label class="form-label" for="address1">Phone Number</label>
            <!-- <select2 class="custom-select intl-tel-input" [templates]="template" [data]="codes"
              formControlName="country_code">
              <ng-template #template let-data="data">
                <div class="country">
                  <div class="flag-box">
                    <div class="iti-flag {{data?.class}}"></div>
                  </div>
                  <span class="dial-code">{{data.code}}</span>
                </div>
              </ng-template>
            </select2> -->
            <input type="number" class="form-control intl-input-padding" formControlName="phone"
              placeholder="Enter Phone Number" name="phone">
            <div class="invalid-feedback"
              *ngIf="submitted && form.controls['phone'].errors?.['required']">
              Phone Number is Required
            </div>
            <div class="invalid-feedback"
              *ngIf="submitted && form.controls['phone'].errors?.['pattern']">
              Phone Number is Invalid
            </div>
          </div>
        </div>
        <div class="col-12">
          <div class="">
            <label class="form-label" for="note1">Order Notes</label>
            <textarea class="form-control" id="note" type="text" placeholder="Notes About Your Order eg: Special Notes For Delivery" rows="3"
              formControlName="note" ></textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <app-button [class]="'btn btn-theme-outline btn-md fw-bold'" [type]="'button'" [spinner]="false"
          [id]="'cancel_addres_btn'" (click)="modal.dismiss('Cancel')">
          Cancel
        </app-button>
        <app-button [class]="'btn theme-bg-color btn-md fw-bold text-light'" [id]="'submit_address_btn'" (click)="submit(modal);">
        Submit
        </app-button>
      </div>
    </div>
  </form>
</ng-template>
