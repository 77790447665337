import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Category, CategoryModel } from '../../../interface/category.interface';
import { CategoryState } from '../../../state/category.state';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent {

  @Select(CategoryState.category) category$: Observable<CategoryModel>;

  @Input() categoryIds: number[] = [];
  @Input() style: string = 'vertical';
  @Input() title?: string;
  @Input() image?: string;
  @Input() theme: string;
  @Input() sliderOption: OwlOptions;
  @Input() selectedCategoryId: number;
  @Input() bgImage: string;
  catagoryArr : any = [];
  imgUrl : any = environment?.imageUrl

  @Output() selectedCategory: EventEmitter<number> = new EventEmitter();

  categories: any = [];
  public selectedCategorySlug: string[] = [];

  constructor(private route: ActivatedRoute,
    private router: Router, private productService : ProductService) {
    this.category$.subscribe(res => this.categories = res.data.filter(category => category.type == 'product'));
    this.route.queryParams.subscribe(params => {
      this.selectedCategorySlug = params['category'] ? params['category'].split(',') : [];
    });
  }

  ngOnChanges() {
    const id = environment?.sh_id;
    this.productService.getCatagoryList(id).subscribe((res:any)=>{
      if(res){
        this.catagoryArr  = res;
      }
    },(error:any)=>{
      
    })
  }

  selectCategory(id: number) {
    this.selectedCategory.emit(id);
  }

  redirectToCollection(catagoryName: any,cata_id: any) {
    this.router.navigate(['/collections',catagoryName,cata_id], 
    {
      relativeTo: this.route,
      queryParams: {
        category: this.selectedCategorySlug.length ? this.selectedCategorySlug.join(',') : null
      },
      queryParamsHandling: 'merge', // preserve the existing query params in the route
      skipLocationChange: false  // do trigger navigation
    }
    );
  }

}
