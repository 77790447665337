import { APP_INITIALIZER } from '@angular/core';
import { ProductService } from './shared/services/product.service';  
import { environment } from 'src/environments/environment';

export function initializeLogo(productService: ProductService) {
  return (): Promise<any> => {
    // Make sure this returns a Promise
    return productService.getShoDetails(environment?.sh_id)
      .toPromise()
      .then((response: any) => {
        if(response){
          productService.setLogoUrl(response.url);

        }
      },(error:any)=>{});
  };
}

export const appInitializerProviders = [
  {
    provide: APP_INITIALIZER,
    useFactory: initializeLogo,
    deps: [ProductService],
    multi: true
  }
];
