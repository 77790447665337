import { Component, TemplateRef, ViewChild, PLATFORM_ID, Inject, Output, EventEmitter, OnInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Select, Store } from '@ngxs/store';
import { map, Observable } from 'rxjs';
import { Select2Data, Select2UpdateEvent } from 'ng-select2-component';
import { CountryState } from '../../../../state/country.state';
import { StateState } from '../../../../state/state.state';
import { UserAddress } from '../../../../interface/user.interface';
import * as data from '../../../../data/country-code';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/shared/services/account.service';
import { ToastrService } from 'ngx-toastr';
import { CountryService } from 'src/app/shared/services/country.service';
import { error } from 'console';


@Component({
  selector: 'address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss']
})
export class AddressModalComponent implements OnInit {

  public form: FormGroup;
  public closeResult: string;
  public modalOpen: boolean = false;
  id : any ;

  public states$: Observable<Select2Data>;
  address: boolean = false ;
  public codes = data.countryCodes;
  userId : any = '';
  submitted = false;
  indianPincodePattern = /^[1-9][0-9]{5}$/;
  usZipcodePattern = /^\d{5}(-\d{4})?$/;
  addressDetail :  any = '';
  label : any = ''
  isChecked: boolean = false; 
  @ViewChild("addressModal", { static: false }) AddressModal: TemplateRef<string>;
  @Select(CountryState.countries) countries$: Observable<Select2Data>;
  @Output()updateItem: EventEmitter<any> = new EventEmitter();
  countryArr : any = [];
  stateArr : any = [];
  countries : any = []
  isPincodeValid: boolean = false;
  pincodemessage: any;
  pincodeError: string | null = null;
  constructor(private modalService: NgbModal, private productservice:ProductService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private store: Store,
    private formBuilder: FormBuilder,private ProductService : ProductService, private AccountService : AccountService,private toastr: ToastrService, private CountryService : CountryService, ) {
      this.CountryService.getCountries().subscribe((res:any)=>{
        this.countries = res
        res.forEach((item:any)=>{
           this.countryArr.push({
            label : item?.name,
            id:item?.id
           })
        })
        
      })
     this.setForm();
     this.userId = this.AccountService.getUserData()?.id;
    }
ngOnInit(): void {
this.form.value.reset();

}
  setForm(){
    this.form = this.formBuilder.group({
      fullname: new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z\s]+$/)]),
      street: new FormControl('', [Validators.required]),
      email: new FormControl('', [Validators.required,Validators.email]),
      state_id: new FormControl(null, [Validators.required]),
      country_id: new FormControl(null, [Validators.required]),
      city: new FormControl('', [Validators.required,Validators.pattern(/^[a-zA-Z\s]+$/)]),
      pincode: new FormControl('', [Validators.required,this.multiPatternValidator()]),
      // country_code: new FormControl('91'),
      phone: new FormControl('', [Validators.required, Validators.pattern(/^\d{10}$|^\d{12}$/),]),
      note: new FormControl('')
    })
  }
  onInputChange(): void {
  const pincodeValue = this.form?.value?.pincode;

  if (pincodeValue) {
    this.pincodemessage = null; 
    this.isPincodeValid = true; 
  }
}
  oncheck(){
    if (!this.form?.value?.pincode) {
      this.isPincodeValid = false;
      this.pincodemessage = 'Pincode is required';  
    }
    else{
      
      
      this.isChecked = !this.isChecked
      if(this.isChecked==true){
        const data={
          'shop_id': environment?.sh_id,
          'pincode': this.form?.value?.pincode
          }
    
          this.productservice.pincodevalidation(data).subscribe((result:any)=>{
           console.log(result,'pincodesssssss')
           if(result.message){
             this.isPincodeValid = true;
             this.pincodemessage=result.message;
           }
          },
          (error:any)=>{
           this.isPincodeValid = false;
           this.pincodemessage=error.error?.message;
           console.log(error,'errror')
          }
         )
       
        
         
      }
      
      else{
        this.isPincodeValid = true;
        this.pincodemessage =null;
        this.form.get('pincode')?.reset(''); 
        
      
    }
   
   
  }}

  multiPatternValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      if (!value) {
        return null;
      }
      // Check against multiple patterns
      const valid = this.indianPincodePattern.test(value) || this.usZipcodePattern.test(value);
      return valid ? null : { 'pincodeInvalid': true };
    };
  }

  countryChange(data: any,type:any) {
   this.stateArr = [];
   this.label = type == 'from_update' ? data : data?.target?.value;
    this.countries.forEach((item:any)=>{
    if(item?.name == this.label){
      item?.state.forEach((stateitem:any)=>{
        this.stateArr.push({
          label : stateitem?.name,
          id:stateitem?.id
         })

      })

    }

  })
  }

  async openModal(value?: any) {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      this.modalOpen = true;
      this.id = value;
      this.patchForm(value);
      this.modalService.open(this.AddressModal, {
        ariaLabelledBy: 'address-add-Modal',
        centered: true,
        windowClass: 'theme-modal modal-lg'
      }).result.then((result) => {
        `Result ${result}`
      }, (reason) => {
       this.submitted = false;
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }
  }


  private getDismissReason(reason: ModalDismissReasons): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
      
    }
  }

  patchForm(value?: any) {
    if(value) {
      this.ProductService.getAddressDetail(value).subscribe((res:any)=>{
        if(res?.data){
          this.address = true;
          this.countryChange(res?.data?.country,'from_update');
          this.form.patchValue({
            fullname : res?.data?.firstname + ' ' + (res?.data?.lastname != null? res?.data?.lastname : ''),
            street : res?.data?.address,
            email : res?.data?.email,
            state_id :res?.data?.state,
            country_id : res?.data?.country,
            city : res?.data?.city,
            pincode : res?.data?.zipcode,
            phone : res?.data?.phone,
            note : res?.data?.note
          })
        }
      },(error:any)=>{})
    } else {
      this.address = false;
      this.form.reset();
      this.form?.get('country_code')?.setValue('91');
    }
  }

  submit(modal:any){
    this.form.markAllAsTouched();
    console.log(this.form?.value?.country_id,this.form?.value?.state_id,'state_id')
    this.submitted = true;
    if(this.form.valid) {
      const full_name = this.form.value.fullname.split(' ');
      const firstname = full_name[0];
      const lastname = full_name[1];
      const data = {
        firstname : firstname,
        lastname : lastname,
        country : this.form?.value?.country_id,
        city : this.form?.value?.city,
        state : this.form?.value?.state_id,
        zipcode : this.form?.value?.pincode,
        email : this.form?.value?.email,
        phone : this.form?.value?.phone,
        address : this.form?.value?.street,
        note : this.form?.value?.note ? this.form?.value?.note : '' ,
        shop : environment?.sh_id,
        customer : this.userId
      }
      if(!this.address){
        this.ProductService.addBillAddress(environment?.sh_id,data).subscribe((res:any)=>{
          if(res?.data){
            this.ProductService.getBillAddress(true)
            this.toastr.success('Address added successfully','success');
            modal.close();
           
           
        }
      },(error:any)=>{})
      }else{
        this.ProductService.updateAddress(this.id,data).subscribe((res:any)=>{
          this.updateItem.emit(true);
          modal.close();
          this.form.reset();
          
          
        },(error:any)=>{})
      }
     
    }
  }

  ngOnDestroy() {
    if(this.modalOpen) {
      this.modalService.dismissAll();
    }
  }

}
