import { Component, Input } from '@angular/core';
import { Option } from '../../../../../shared/interface/theme-option.interface';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-footer-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent {

  @Input() data: Option | null;
  email = 'johnsmith@gmail.com'

  contactDetail : any;
  contact: any;
  address: any;
  constructor(private ProductService : ProductService){
    this.getContactDetails()
  }

  getContactDetails(){
    const sh_id = environment.sh_id;
    this.ProductService.getContactDetails(sh_id).subscribe((res:any)=>{
      if(res){
      this.contactDetail = res;
      console.log(this.contactDetail,'contactss')
      this.contact=this.contactDetail?.mobile
      this.email=this.contactDetail?.email2
    }
    },(error:any)=>{})
      
  }

}
