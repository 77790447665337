import { Component, Input, OnInit } from '@angular/core';
import { Option } from '../../../../interface/theme-option.interface';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit {
 

  @Input() data: Option | null;
  shopDetails : any ;
  imgUrl = environment?.imageUrl

  constructor(private ProductService : ProductService){}

  ngOnInit(): void {
    this.ProductService.getmessage(environment?.sh_id).subscribe((res:any)=>{
      if(res){
        this.shopDetails = res.data;
      }
    },(error:any)=>{})
  }

}
