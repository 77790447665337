<!-- Topbar -->
<div class="header-top" [class.bg-dark]="data?.header?.page_top_bar_dark">
    <div class="container-fluid-lg">
        <div class="row">
            <div class="col-xxl-3  col-xl-3 col-lg-3 col-md-3 col-sm-3 ">
                <div class="top-left-header">
                    <a class="text-white" href="https://www.apple.com/in/store" target="_blank">
                        <i class="ri-apple-line"></i>
                        <span class="text-white">{{ 'Get_the_app' | translate }}</span>
                    </a>
                </div>
            </div>
            <div class="col-xxl-6 col-lg-6 col-xl-6 col-md-6 col-sm-7 d-lg-block d-none pt-1">
                <div class="header-offer">
                    <app-notice></app-notice>
                </div>
            </div>
             <!-- <div class="col-xxl-3 col-lg-3 col-xl-3 col-md-3 d-xxl-block  d-sm-none d-flex align-items-center">
                <h6 class="timer-notification">Telephone Enquiry : 6895487654</h6></div> -->
                <!-- <ul class="about-list right-nav-about lists col-lg-3 col-sm-3 col-md-3 col-xl-3 col-xxl-3"> -->
                    <!-- <li class="right-nav-list">
                        <div class="dropdown theme-form-select">
                            <app-language></app-language>
                        </div>
                    </li> -->
                    <!-- <li class="right-nav-list">
                        <div class="dropdown theme-form-select select-arrow">
                            <app-currency></app-currency>
                        </div>
                    </li> -->
                <!-- </ul> -->
            
        </div>
    </div>
</div>


