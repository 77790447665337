import { Component, Input, OnInit } from '@angular/core';
import { Link } from '../../../../../shared/interface/theme-option.interface';
import { AccountService } from 'src/app/shared/services/account.service';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';


@Component({
  selector: 'app-footer-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.scss']
})
export class LinksComponent implements OnInit {
 
  
  @Input() links: Link[] = [];

  constructor(private AccountService : AccountService,private router :Router, private ProductService: ProductService){}

  ngOnInit(): void {
   
  }

  navigateLink(link:any){
    this.ProductService.getMenu({status:true,link:link});
    if(link != 'contact-us' && link != '' && link != 'collections' && link != 'about-us')
      {
    if (this.AccountService.loggedIn()) {
      this.router.navigateByUrl(link);
     
    }else{
      this.router.navigate(['/auth/login']);
    }
  }else{
    this.router.navigateByUrl(link);
  }
}

}
