import { Component, Input, OnInit } from '@angular/core';
import { Option } from '../../../../interface/theme-option.interface';
import { Select } from '@ngxs/store';
import { ThemeOptionState } from '../../../../../shared/state/theme-option.state';
import { Observable } from 'rxjs';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit {
  

  @Input() textClass: string = 'text-white f-w-600';
  @Input() data: Option | null;
  @Input() logo: string | null | undefined;
  shopDetails : any ;
  imgUrl = environment?.imageUrl

  @Select(ThemeOptionState.themeOptions) themeOption$: Observable<Option>;

  constructor(private ProductService : ProductService,private router: Router){}

  ngOnInit(): void {
    this.ProductService.getShoDetails(environment?.sh_id).subscribe((res:any)=>{
      if(res){
        this.shopDetails = res;
      }
    },(error:any)=>{})
  }
  routingToHome(){
    this.ProductService.getPageLoader(true)
    this.router.navigate(['']).then(() => {
      // Refresh the page after navigating
      
      setTimeout(() => {
        this.ProductService.getPageLoader(false);

      }, 2000);

  })
  
}

}
