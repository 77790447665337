import { Component, Input, SimpleChanges, ViewChild,OnChanges } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { NgbRatingConfig } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ProductDetailModalComponent } from '../../../widgets/modal/product-detail-modal/product-detail-modal.component';
import { CurrencyPipe } from '@angular/common';
// import { AddToCart } from '../../../../../shared/action/cart.action';
import { CartState } from '../../../../../shared/state/cart.state';
import { VariationModalComponent } from '../../modal/variation-modal/variation-modal.component';
import { ProductService } from 'src/app/shared/services/product.service';
import { AccountService } from 'src/app/shared/services/account.service'; 
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AddToWishlist } from 'src/app/shared/action/wishlist.action';
import { error } from 'console';



@Component({
  selector: 'app-product-box-horizontal',
  templateUrl: './product-box-horizontal.component.html',
  styleUrls: ['./product-box-horizontal.component.scss']
})
export class ProductBoxHorizontalComponent {
items:any=[];

product_info:any=[];
userId : any;
secondarray:any=[];
  @Input() product: any = [];
  @Input() class: string;
  @Input() close: boolean;
  @Input() wishlistIcon : boolean;
  wishlistFlag = false;


  // @Select(CartState.cartItems) cartItem$: Observable<Cart[]>;

  @ViewChild("productDetailModal") productDetailModal: ProductDetailModalComponent;
  @ViewChild("variationModal") VariationModal: VariationModalComponent;

  // public cartItem: Cart | null;
  public currentDate: number | null;
  public saleStartDate: number | null;
  catagoryList : any = [];
  apiUrl = environment.imageUrl;
 
skid:any;

  constructor(private store: Store,private currencyPipe: CurrencyPipe,
    config: NgbRatingConfig, private productService : ProductService, private AccountService : AccountService, private router : Router,  private toastr: ToastrService) {
		config.max = 5;
		config.readonly = true;

  }

  ngOnInit() {
    console.log(this.product,'PRODUCTSSSSSSSSSSSSSSSSSS')
    if( this.AccountService.getUserData()){
     this.userId = this.AccountService.getUserData()?.id;}
     this.product.skid = this.product?.sku_id ? this.product?.sku_id : this.product?.skid;
     this.product.prId = this.product?.id
     this.product.id = this.product?.productid ? this.product?.productid : this.product?.id
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   const currentCategory = changes['product'].currentValue;
  //   // if(currentCategory){
  //   //   this.productArr = currentCategory;
  //   // }
  //   console.log(currentCategory,'catghkk')
   
  // }

  // addToCart(product: Product, qty: number) {
  //   const params: CartAddOrUpdate = {
  //     id: this.cartItem ? this.cartItem.id : null,
  //     product: product,
  //     product_id: product?.id,
  //     variation_id: this.cartItem ? this.cartItem?.variation_id : null,
  //     variation: this.cartItem ? this.cartItem?.variation : null,
  //     quantity: qty
  //   }
  //   this.store.dispatch(new AddToCart(params));
  // }

  addToWishlist(id: number,skid:number) {
    if(this.userId != undefined){
    var dataArr : any = {
      'product_id' : id,
      'sku_id'     : Number(skid),
      'userid'     : this.userId
    }
    this.productService.addWishList(dataArr).subscribe((res:any)=>{
      if(res?.message){
          this.product.is_wishlist = res?.is_wishlist
          this.productService.getwishlistFlag(true)
      }
     
    },(error:any)=>{})
     }else{
    // this.toastr.error('Not logged in yet!');
    this.router.navigate(['/auth/login'])


  }
  }
  removeWishlist(id: number){
    console.log(id,'iiii')
    // this.store.dispatch(new DeleteWishlist(id));
    this.productService.deleteWishlistProduct(id).subscribe((res:any)=>{
      if(res[0] == 'success'){
        this.toastr.success('Removed','success');
        this.wishlistFlag = false;
        this.productService.getwishlistFlag(true)
      }else{
        this.toastr.error(res[0],'error');
        this.productService.getwishlistFlag(false)
      }
    },(error:any)=>{})
  }

  addToCompar(prid:any,skid:any){
      const shid=environment?.sh_id;
      if(this.userId!==undefined){
        
        var datass : any = 
        {
              'product_id'  : prid,
              'sku_id'     : skid,
              'shop_id'    :  shid,
              'user_id'     : this.userId
        }
          this.productService.addCompareProduct(datass).subscribe((result:any)=>{
          this. items=result;
          this.productService.updateTotalCount(true);
          this.router.navigate(['/compare'])
        })
      }
      else{
            var items :any= localStorage.getItem('productinfo') || [];
            const parsedData = items?.length !=0 ? JSON.parse(items) : [];
            const product_info = {
            'product_id'  : Number(prid),
            'sku_id'     : Number(skid),
            }
            parsedData.push(product_info)
            localStorage.setItem('productinfo',JSON.stringify(parsedData))
            const secondvalue:any=localStorage.getItem('productinfo')
            this.secondarray=JSON.parse(secondvalue)
            var datas:any=
            {
            'product_info':this.secondarray,
            }
          this.productService.notloggedaddproduct(datas,shid).subscribe((result:any)=>{
          if(result)
        {
          localStorage.setItem('addtocompare',JSON.stringify(result));
          this.productService.updateTotalCount(true);
          this.router.navigate(['/compare'])
        }

         })
      }
}
}       
       
  