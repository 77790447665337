import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AccountService } from 'src/app/shared/services/account.service';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';
import { error } from 'console';

@Component({
  selector: 'app-header-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {

  @Input() style: string = 'basic';
  wishlistCount : any = 0;
  wishlistFlag = false;

  // @Select(WishlistState.wishlistItems) wishlist$: Observable<WishlistModel>;

  constructor(private AccountService : AccountService,private router :Router, private ProductService : ProductService,private cdr: ChangeDetectorRef){}


  ngOnInit(): void {
    this.getWishList();
    this.ProductService.setwishlistFlag().subscribe((res:any)=>{
      if(res == true){
        this.getWishList()
      }else{
        this.wishlistFlag = false
      }
  })
    
  }

  getWishList(){
    this.cdr.detectChanges(); 
    const userId = this.AccountService.getUserData()?.id;
    if(userId != undefined){
    this.ProductService.getWishList(userId).subscribe((res:any)=>{
      if(res){
        this.wishlistCount = res?.length;
        this.wishlistFlag = true;
      }
    },(error:any)=>{})
  }else{
    this.wishlistFlag = false;
  }
  }

  navigateLink(){
    if(this.AccountService.loggedIn()) {
      this.router.navigateByUrl('wishlist');
    }
  }

}
