import { Component, Input, OnInit } from '@angular/core';
import { Option } from '../../../../shared/interface/theme-option.interface';
import { Footer } from '../../../../shared/interface/theme.interface';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-basic-footer',
  templateUrl: './basic-footer.component.html',
  styleUrls: ['./basic-footer.component.scss']
})
export class BasicFooterComponent implements OnInit {

  @Input() data: Option | null;
  @Input() footer: Footer;


  shopdetailes: any=[];
    shopName: any;
  contactDetail: any=[];
  address: any;
  
    constructor( private productservice:ProductService){
  
  
    }
  
  ngOnInit(): void {
    const sh_id = environment.sh_id;
    this.productservice.getContactDetails(sh_id).subscribe((res:any)=>{
      this.contactDetail = res;
      console.log(this.contactDetail,'contactss')
      this.address=this.contactDetail?.address
     
      
     
    })
  }
      
   
  
  
  
  
  public active: { [key: string]: any }  = {
    categories: false,
    useful_link: false,
    help_center: false,
  };

  linkdata =   [
    {
      "label": "Home",
      "link": ""
    },
    {
      "label": "Collections",
      "link": "collections"
    },
    {
      "label": "About Us",
      "link": "about-us"
    },
   
    
  ]

  helpCenter =  [
    {
      "label": "My Account",
      "link": "account/dashboard"
    },
    {
      "label": "My Orders",
      "link": "account/order"
    },
    {
      "label": "Wishlist",
      "link": "wishlist"
    },
   
    {
      "label": "Contact Us",
      "link": "contact-us"
    }
  ]

  toggle(value: string){
    this.active[value] = !this.active[value];
  }

}
