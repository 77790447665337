import { Component, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Menu } from '../../../interface/menu.interface';
import { ProductState } from '../../../../shared/state/product.state';
import { Product, ProductModel } from '../../../../shared/interface/product.interface';
import { BlogState } from '../../../../shared/state/blog.state';
import { Blog, BlogModel } from '../../../../shared/interface/blog.interface';
import * as data from '../../../../shared/data/menu';
import { Router } from '@angular/router';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';
import { error } from 'console';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  @Select(ProductState.dealProducts) product$: Observable<Product[]>;
  @Select(BlogState.blog) blog$: Observable<BlogModel>;

  public menu: any[] = data.menu;
  public products: Product[];
  public blogs: any[];
  cateogry: any [];
  catagoryArr: any[];
  imgUrl = environment?.imageUrl
  constructor(private router : Router ,private ProductService:ProductService){
    this.product$.subscribe(product => {
      this.products = product.slice(0, 2);
    })

    this.blog$.subscribe(blog =>{
      this.blogs = blog.data.slice(0,2)
    })
  }


  toggle(menu:Menu) {
    menu.active = !menu.active;
  }

  ngOnInit(): void {
    // this.menu[1].children[0].children[0].push({
    //   title : 'Catagory',
    //   type:'Sub'
    // })

    this.ProductService.getCatagoryList(environment?.sh_id).subscribe((res:any)=>{
        if(res){
          res.forEach((item:any)=>{
            this.menu[1].children[0].children.push({
              title: item?.maincategory,
              path: 'collections',
              params: { layout: item?.maincategory  },
              type: 'link',
              // label: 'hot',
              // labelClass: 'warning-label',
          })
        //   this.menu[1].children[1].children.push({
        //     title: 'Sandals',
        //     path: 'collections',
        //     params: { layout: 'collection_category_slider' },
        //     type: 'link',
        // })
        })
      }
    })
    const sh_id = environment?.sh_id;
    this.ProductService.getFeaturedProduct(sh_id).subscribe((res:any)=>{
    this.cateogry=res.slice(0,2);
    },(error:any)=>{})
    
    this.ProductService.getFeaturedProduct(sh_id).subscribe((res:any)=>{
      this.catagoryArr  = res;
    },(error:any)=>{});

    this.ProductService.getFeaturedBlog(sh_id).subscribe((res:any)=>{
      this.blogs=res;
      if(res?.length){
        this.blogs=res.slice(0,2);
        // console.log(this.blogs,res,'blogsssyyy')
      }else{
        this.blogs = []
      }
     
    },(error:any)=>{});

    if (this.menu.length > 0) {
      this.menu[0].active = true;
    }
    this.menuClick()
   
  }
  // onclicks(menu:Menu){
    
  // }

  menuClick(){
    this.ProductService.setMenu().subscribe((res:any)=>{
      if(res?.status == true){
        this.menu.forEach((item:any)=>{
          if(item?.path == res?.link){
            item.active = true;
            // return
          }else{
            item.active = false;
          }
    
        })
      }
     
    })
  }

  onclicks(menu:Menu) {   
  
    
    this.deactivateAllMenus(this.menu);    
    
      menu.active = true;   
    }   
    deactivateAllMenus(menus: any[]) {     
          menus.forEach(menu => {      
        menu.active = false; 
          
      if (menu.children && menu.children.length > 0) 
        { this.deactivateAllMenus(menu.children); } }); }
  

}
