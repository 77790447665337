import { Component, Input, SimpleChanges } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CategoryState } from '../../../../../shared/state/category.state';
import { Option } from '../../../../../shared/interface/theme-option.interface';
import { CategoryModel, Category } from '../../../../../shared/interface/category.interface';
import { environment } from 'src/environments/environment';
import { ProductService } from 'src/app/shared/services/product.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class FooterCategoriesComponent {

  @Input() data: Option | null;
  @Select(CategoryState.category) category$: Observable<CategoryModel>;
   public categories: any[];

   constructor(private ProductService : ProductService, private router: Router){

   }

  ngOnChanges() {
    // const ids = changes['data']?.currentValue?.footer?.footer_categories
    // if (Array.isArray(ids)) {
    //   this.category$.subscribe(categories => {
    //     if(Array.isArray(categories.data)) {
    //       this.categories = categories.data.filter(category => ids?.includes(category.id));
    //     }
    //   })
    // }
    const id = environment?.sh_id;
    this.ProductService.getCatagoryList(id).subscribe((res:any)=>{
      if(res){
        this.categories  = res;
      }
    },(error:any)=>{})
  }

  redirectToCollection(catagoryName: any,cata_id: any) {
    this.router.navigate(['/collections',catagoryName,cata_id]);
  }

}
