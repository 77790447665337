import { Menu } from "../interface/menu.interface";

export const menu: Menu[] = [
  {
    id: 1,
    title: 'Home',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'image',
    active: false,
    path : ''
   
  },
  {
    id: 2,
    title: 'Collections',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'link',
    path: 'collections',
    active: false,
    slider: 'product',
    children: [
      {
        children: [
          {
            title: 'Category',
            type: 'sub',
          },
        ],
      },
      // {
      //   children: [
      //     {
      //       title: 'Subcategory',
      //       type: 'sub',
      //     },
      //     {
      //       title: 'Sandals',
      //       path: 'collections',
      //       params: { layout: 'collection_category_slider' },
      //       type: 'link',
      //     },
      //     {
      //       title: 'Lipstick',
      //       path: 'collections',
      //       params: { layout: 'collection_category_sidebar' },
      //       type: 'link',
      //       label: 'new',
      //     },
      //     {
      //       title: 'Jeans',
      //       path: 'collections',
      //       params: { layout: 'collection_banner' },
      //       type: 'link',
      //     },
      //     {
      //       title: 'Gown',
      //       path: 'collections',
      //       params: { layout: 'collection_offcanvas_filter' },
      //       type: 'link',
      //     },
      //   ],
      // },
    ],
  },
 
  {
    id: 3,
    title: 'blog',
    type: 'link',
    megaMenu: true,
    megaMenuType: 'link',
    path: 'blog/:id',
    active: false,
    slider: 'blog',
    children: [
      {
        children: [
          // {
          //   title: 'Blog Pages',
          //   type: 'sub',
          // },
          // {
          //   title: 'Blog List',
          //   type: 'link',
          //   path: 'blogs',
          //   params: { style: 'list_view', sidebar: 'left_sidebar' },
          //   label: 'new',
          // },
        //  {
        //     title: 'blog_details',
        //     path: 'blog/supercharge-your-meals-incorporating-colorful-vegetables-and-fruits',
        //     type: 'link',
        //   },
        ],
      },
    ],
  },

   

  {
    id: 4,
    title: 'About Us',
    type: 'link',
    path: 'about-us',
    megaMenuType: 'sub',
    active: false,

  },
  
  {
    id:5,
    
      title: 'Offers',
      type: 'link',
      path: 'offer',
      label: 'new',
    }
      
      
     
      
     
     
      
   
];
