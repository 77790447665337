<div class="theme-logo">
    <a [routerLink]="['/']">
        <img [src]="shopDetails?.logo ? imgUrl+ shopDetails?.logo : ''" class="img-fluid imgefluid sizes" >
        <ng-template #text>
            <h2 class="f-w-600" *ngIf="data?.general">
                {{ data.general.site_title ? data.general.site_title.split(' ')[0] : 'Logo Here' }}
            </h2>
        </ng-template>
    </a>
</div>
