import { Component, Input,OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { CartState } from '../../../../state/cart.state';
import { DeleteCart, GetCartItems, ToggleSidebarCart, UpdateCart } from '../../../../action/cart.action';
import { ThemeOptionState } from '../../../../state/theme-option.state';
import { Option } from '../../../../interface/theme-option.interface';
import { SettingState } from '../../../../state/setting.state';
import { Values } from '../../../../interface/setting.interface';
import { CartService } from '../../../../services/cart.service';
import { AccountService } from 'src/app/shared/services/account.service';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';
import { json } from 'express';
import { CurrencyPipe } from '@angular/common';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-header-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
})
export class CartComponent implements OnInit {

  @Select(CartState.cartItems) cartItem$: Observable<any[]>;
  // @Select(CartState.cartTotal) cartTotal$: Observable<number>;
  @Select(CartState.sidebarCartOpen) sidebarCartOpen$: Observable<boolean>;
  @Select(ThemeOptionState.themeOptions) themeOption$: Observable<Option>;
  @Select(SettingState.setting) setting$: Observable<Values>;


  @Input() style: string = 'basic';

  public cartStyle: string = 'cart_sidebar';
  public cartTotal: number = 0;
  public shippingCal: number = 0;
  public loader: boolean = false;
  userId : any ;
  cartList : any = [];
  imageUrl = environment.imageUrl;
  noData = false;
  cartShow = false;
  pincodevalues: any;

  constructor( private currency:CurrencyPipe,private store: Store, public cartService: CartService,private AccountService : AccountService, private ProductService : ProductService,private toastr: ToastrService) {
   
  }

  ngOnInit(){
        this.cartShow = false;
        this.ProductService.setpincode().subscribe((result:any)=>{          
          if(result){            
            this.pincodevalues=result;           
            //  this.getTempProductInCart();          
            }        
        })
        this.ProductService.setCartlistFlag().subscribe((res:any)=>{

        if(this.AccountService.getUserData()){

        this.userId = this.AccountService.getUserData()?.id;

        }
        if(res == true  && this.userId != undefined){
         
          this.getProductInCart()

        }else if(res == true  && this.userId == undefined){
          
          this.getTempProductInCart()
        }
        else if(res == false){
          this.cartList = []
        }
        else{}
      })
      if(!this.ProductService?.getlocalcart()){
        this.noData = true;
      }
  }

  cartToggle(value: boolean) {
    this.store.dispatch(new ToggleSidebarCart(value));
  }

  getProductInCart(){
    this.cartList = [];
    this.cartTotal = 0;
    if(this.userId != undefined){
      this.ProductService.getCartProductList(this.userId,environment?.sh_id).subscribe((res:any)=>{
      if(res.length !=0){
        this.cartList = res;
        this.noData = false;
        this.cartList.forEach((item:any)=>{
          this.cartTotal += item?.sales_rate * item?.product_quantity
      })
      }else{
        this.noData = true;
      }
    },(error:any)=>{})
  }else{

  }
  }

  // updateQuantity(item: Cart, qty: number) {
  //   const params: CartAddOrUpdate = {
  //     id: item?.id,
  //     product_id: item?.product?.id,
  //     product: item?.product ? item?.product : null,
  //     variation_id: item?.variation_id ? item?.variation_id : null,
  //     variation: item?.variation ? item?.variation : null,
  //     quantity: qty
  //   }
  //   this.store.dispatch(new UpdateCart(params));
  // }

  delete(id: number) {
    if(this.userId != undefined){
    this.ProductService.deleteCartProduct(id).subscribe((res:any)=>{
      if(res){
        this.getProductInCart();
        this.ProductService.getCartlistFlag(true);

      }
    },(error:any)=>{})
  }else{
    var products_info: any = localStorage.getItem('add_cart')
    const parseData = JSON.parse(products_info)
    const index = parseData.findIndex((item:any) => item.product_id === id);
    if (index !== -1) {
      // Remove the product from the array
      parseData.splice(index, 1);
      localStorage.setItem('add_cart', JSON.stringify(parseData));
      this.ProductService.getCartlistFlag(true);
      this.getTempProductInCart()
    } 
  }
  }

  getTempProductInCart(){
    this.cartList = [];
    this.cartTotal = 0;
    var products_info = this.ProductService.getlocalcart()
    if(products_info){
      const data = {
        shopid : environment?.sh_id,
        products_info : products_info,
        pincode : this.pincodevalues
      }
      this.ProductService.getTempCart(data).subscribe((res:any)=>{
        if(res?.status == 'success' && res?.cart_details?.length != 0){
          res?.cart_details.forEach((item:any)=>{
            item.id = item?.product_id
          })
          this.cartList = res?.cart_details;
          this.noData = false;
          this.cartList.forEach((item:any)=>{
            this.cartTotal += item?.sales_rate * item?.product_quantity
        })
        }else{
          this.noData = true;
        }
      },(error:any)=>{
        this.toastr.error(error?.error?.message,'error')


      })

    }
   

  }

}
