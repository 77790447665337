import { Component, PLATFORM_ID, Inject, OnInit } from '@angular/core';
import { isPlatformBrowser,DatePipe } from '@angular/common';
import { Select } from '@ngxs/store';
import { interval, Observable, switchMap,  Subscription} from 'rxjs';
import { Product, ProductModel } from '../../../interface/product.interface';
import { ProductState } from '../../../state/product.state';
import { ProductService } from 'src/app/shared/services/product.service';
import { environment } from 'src/environments/environment';
// import {  } from '@angular/common';
@Component({
  selector: 'app-recent-purchase-popup',
  templateUrl: './recent-purchase-popup.component.html',
  styleUrls: ['./recent-purchase-popup.component.scss'],
  providers : [DatePipe]
})
export class RecentPurchasePopupComponent implements OnInit {

  @Select(ProductState.relatedProducts) relatesProduct$: Observable<Product[]>;
  @Select(ProductState.product) product$: Observable<ProductModel>;

  public product: Product | null;
  public show: boolean = false;
  public min: number = 10;
  public popup_enable: boolean = true;
  popup: any;
  image_url=environment?.imageUrl;
  formattedDate: string;
  subscription: Subscription = new Subscription();

  constructor(@Inject(PLATFORM_ID) public platformId: Object, public productservice:ProductService, public datePipe : DatePipe) {
    if (isPlatformBrowser(this.platformId)) { // For SSR 
      if(this.popup_enable) {
        setInterval(() => {
          this.show = true;
          this.min = Math.floor(Math.random() * 60) + 1;
          this.randomlySelectProduct();
          setTimeout(() => {
            this.show = false;
          }, 5000);
        }, 20000);
      }
    }
  }
ngOnInit(): void {
const shid=environment?.sh_id;
this.subscription = interval(5000) 
.pipe(
  switchMap(() => this.productservice.getpopup(shid)) 
)
.subscribe((res: any) => {
  if(res){
    this.popup = res;
    this.formattedDate = this.datePipe.transform(this.popup?.delivered_date, 'dd-MM-yy  HH:mm')!;
      // console.log(res,'resultis');
  }
},(error:any)=>{

});
}
  randomlySelectProduct() {
    this.product$.subscribe(product => {
      if(!product.data.length) {
        this.relatesProducts();
      } else {
        const randomIndex = Math.floor(Math.random() * product.data.length);
        this.product = product.data[randomIndex];
      }
    });
  }

  relatesProducts() {
    this.relatesProduct$.subscribe(products => {
      const randomIndex = Math.floor(Math.random() * products.length);
      this.product = products[randomIndex];
    });
  }

  closePopup() {
    this.popup_enable = false;
  }
}
